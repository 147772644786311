import axios from 'axios';
import store from '@/assets/js/store';
import router from "@/assets/js/router";
import {ElMessage} from "element-plus";


const request = axios.create({
    baseURL: store.state.backURL,
    timeout: 100000
});

// request 拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    // let usertoken = localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):null

    let usertoken = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    let userType = localStorage.getItem("userType");
    let password = localStorage.getItem("password");
    let emailOtpToken = localStorage.getItem("email-otp-token");
    let client = localStorage.getItem("client");

    config.headers['token'] = usertoken;  // 设置请求头
    config.headers['username'] = username;
    config.headers['userType'] = userType;
    config.headers['password'] = password;
    config.headers['client'] = client;
    config.headers['email-otp-token'] = emailOtpToken;

    return config;
}, error => {
    return Promise.reject(error);
});

// response 拦截器
request.interceptors.response.use(
    response => {
        let res = response.data;
        // token失效
        if (res.statusCode === 50002) {
            if (router.currentRoute.value.path === "/signup/query/res") {
                router.replace('/signup/query');
                ElMessage({
                    type: 'error',
                    message: '请重新查询'
                });
            } else {
                router.replace('/login');
                ElMessage({
                    type: 'error',
                    message: '请重新登录'
                });
            }
            this.$message.error(res.message);
        }

        // token续租
        if (response.headers['token'] !== null && response.headers['token'] !== undefined) {
            localStorage.setItem('token', response.headers['token']);
        }
        // email-otp-token续租
        if (response.headers['email-otp-token'] !== null && response.headers['email-otp-token'] !== undefined) {
            localStorage.setItem('email-otp-token', response.headers['email-otp-token']);
        }

        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res;
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res;
        }
        return res;
        // return response;
    },
    error => {
        return Promise.reject(error);
    }
);


export default request;

