import {createRouter, createWebHistory} from 'vue-router';
import {defineAsyncComponent} from "vue";
import request from "@/assets/js/request/request.js";

const router = createRouter({
    history: createWebHistory(),  // history 模式
    routes: [
        {
            path: "/mobile/signup/:itemID(\\d+)/1",
            name: 'MobileSignUp',
            component: defineAsyncComponent(() => import('@/mobileViews/public/signup/MobileSignUp.vue'))
        },
        {
            path: "/mobile/signup/:itemID(\\d+)/2",
            name: 'MobileSignUpLQ',
            component: defineAsyncComponent(() => import('@/mobileViews/public/signup/MobileSignUpLQ.vue'))
        },
        {
            path: "/mobile/signup/:itemID(\\d+)/3",
            name: 'MobileSignUpNanNing',
            component: defineAsyncComponent(() => import('@/mobileViews/public/signup/MobileSignUpNanNing.vue'))
        },
        {
            path: '/',
            name: 'Public',
            component: defineAsyncComponent(() => import('@/views/Public.vue')),
            children: [
                {
                    path: '',
                    name: 'Home',
                    title: '首页',
                    component: defineAsyncComponent(() => import('@/views/public/Home.vue'))
                },
                {
                    path: 'download',
                    name: 'Download',
                    title: '奖状下载',
                    component: defineAsyncComponent(() => import('@/views/public/Download.vue')),
                },
                {
                    // 东信杯-报名表单
                    path: 'signup/:itemID(\\d+)/1',
                    name: 'SignUp',
                    title: '东信杯报名',
                    component: defineAsyncComponent(() => import('@/views/public/signup/SignUp.vue'))
                },
                {
                    // 蓝桥杯-转账记录
                    path: 'signup/:itemID(\\d+)/2',
                    name: 'SignUpLQ',
                    title: '蓝桥杯转账记录',
                    component: defineAsyncComponent(() => import('@/views/public/signup/SignUpLQ.vue'))
                },
                {
                    // 南宁市赛-报名表单
                    path: 'signup/:itemID(\\d+)/3',
                    name: 'SignUpNanNing',
                    title: '南宁市赛报名',
                    component: defineAsyncComponent(() => import('@/views/public/signup/SignUpNanNing.vue'))
                },
                {
                    path: 'login',
                    name: 'Login',
                    title: '登录',
                    component: defineAsyncComponent(() => import('@/views/public/Login.vue')),
                },
                {
                    path: 'board/:itemID(\\d+)',
                    name: 'Board',
                    title: '公告',
                    component: defineAsyncComponent(() => import('@/views/public/Board.vue')),
                },
                {
                    path: 'pages/:itemID(\\d+)',
                    name: 'Page',
                    title: '文章',
                    component: defineAsyncComponent(() => import('@/views/public/page/Page.vue'))
                },
                {
                    path: 'list/:listType(notice|news|board|winners|prize|signup)',
                    name: 'PageList',
                    component: defineAsyncComponent(() => import('@/views/public/page/PageList.vue'))
                },
                {
                    path: 'form',
                    name: 'ViewForm',
                    component: defineAsyncComponent(() => import('@/views/public/Form.vue'))
                },
                {
                    path: 'query',
                    name: 'AccountQuery',
                    title: '比赛账号查询',
                    component: defineAsyncComponent(() => import('@/views/public/signup/AccountQuery.vue'))
                },
                {
                    path: 'signup/query',
                    name: 'SignupQuery',
                    title: '报名查询',
                    component: defineAsyncComponent(() => import('@/views/public/query/SignUp.vue'))
                },
                {
                    path: 'signup/query/res',
                    name: 'SignupQueryRes',
                    component: defineAsyncComponent(() => import('@/views/public/query/SignUpRes.vue'))
                },
                {
                    path: 'signup/query/res/NnPermission',
                    name: 'SignupQueryResNnPermission',
                    component: defineAsyncComponent(() => import('@/views/public/query/NnContestPermission.vue'))
                }
            ],
            beforeEnter: (to, from, next) => {
                let client = localStorage.getItem('client');
                // 判断userAgent是否为移动端
                let userAgent = navigator.userAgent;
                let mobile =
                    userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                        ? "/m" : "";
                if (client === null || client === "null" || client === undefined) {
                    request.get("/public/getClient").then((res) => {
                        if (res.statusCode === 50000) {
                            localStorage.setItem('client', res.data);
                        }
                    });
                }
                if (!to.path.startsWith("/m") && mobile === "/m")
                    next({path: mobile + to.path});
                next();
            }
        },
        {
            path: '/m/',
            name: 'PublicMobile',
            component: defineAsyncComponent(() => import('@/mobileViews/Public.vue')),
            children: [
                {
                    path: '',
                    name: 'HomeMobile',
                    title: '首页',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Home.vue'))
                },
                {
                    path: 'download',
                    name: 'DownloadMobile',
                    title: '奖状下载',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Download.vue')),
                },
                {
                    // 东信杯-报名表单
                    path: 'signup/:itemID(\\d+)/1',
                    name: 'SignUpMobile',
                    title: '东信杯报名',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signup/SignUp.vue'))
                },
                {
                    // 蓝桥杯-转账记录
                    path: 'signup/:itemID(\\d+)/2',
                    name: 'SignUpLQMobile',
                    title: '蓝桥杯转账记录',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signup/SignUpLQ.vue'))
                },
                {
                    // 南宁市赛-报名表单
                    path: 'signup/:itemID(\\d+)/3',
                    name: 'SignUpNanNingMobile',
                    title: '南宁市赛报名',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signup/SignUpNanNing.vue'))
                },
                {
                    path: 'login',
                    name: 'LoginMobile',
                    title: '登录',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Login.vue')),
                },
                {
                    path: 'board/:itemID(\\d+)',
                    name: 'BoardMobile',
                    title: '公告',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Board.vue')),
                },
                {
                    path: 'pages/:itemID(\\d+)',
                    name: 'PageMobile',
                    title: '文章',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/page/Page.vue'))
                },
                {
                    path: 'list/:listType(notice|news|board|winners|prize|signup)',
                    name: 'PageListMobile',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/page/PageList.vue'))
                },
                {
                    path: 'form',
                    name: 'ViewFormMobile',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/Form.vue'))
                },
                {
                    path: 'query',
                    name: 'AccountQueryMobile',
                    title: '比赛账号查询',
                    component: defineAsyncComponent(() => import('@/mobileViews/public/signup/AccountQuery.vue'))
                },
                // {
                //     path: 'signup/query',
                //     name: 'SignupQueryMobile',
                //     title: '报名查询',
                //     component: defineAsyncComponent(() => import('@/mobileViews/public/query/SignUp.vue'))
                // },
                // {
                //     path: 'signup/query/res',
                //     name: 'SignupQueryResMobile',
                //     component: defineAsyncComponent(() => import('@/mobileViews/public/query/SignUpRes.vue'))
                // }
            ],
            beforeEnter: (to, from, next) => {
                let client = localStorage.getItem('client');
                let userAgent = navigator.userAgent;
                let mobile =
                    userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                        ? "/m" : "";
                if (client === null || client === "null" || client === undefined) {
                    request.get("/public/getClient").then((res) => {
                        if (res.statusCode === 50000) {
                            localStorage.setItem('client', res.data);
                        }
                    });
                }
                if (to.path.startsWith("/m") && mobile === "")
                    next({path: to.path.replace("/m", "")});
                next();
            }
        },
        {
            path: '/admin',
            name: 'Admin',
            component: defineAsyncComponent(() => import('@/views/Admin.vue')),
            children: [
                {
                    path: '',
                    name: 'Dashboard',
                    component: defineAsyncComponent(() => import('@/views/admin/Dashboard.vue'))
                },
                {
                    path: 'user',
                    name: 'User',
                    component: defineAsyncComponent(() => import('@/views/admin/UserMgr.vue'))
                },
                {
                    path: 'contest',
                    name: 'Contest',
                    component: defineAsyncComponent(() => import('@/views/admin/ContestMgr.vue'))
                },
                {
                    path: 'form/old',
                    name: 'Form',
                    component: defineAsyncComponent(() => import('@/views/admin/FormMgrOld.vue'))
                },
                {
                    path: 'form/new',
                    name: 'FormNew',
                    component: defineAsyncComponent(() => import('@/views/admin/FormMgrNew.vue'))
                },
                {
                    path: "form/add",
                    name: "AddForm",
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormEditor.vue'))
                },
                {
                    path: "form/edit/:itemID",
                    name: "EditForm",
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormEditor.vue'))
                },
                {
                    path: "form/data",
                    name: "FormData",
                    component: defineAsyncComponent(() => import('@/views/admin/form/FormData.vue'))
                },
                {
                    path: 'email',
                    name: 'Email',
                    component: defineAsyncComponent(() => import('@/views/admin/EmailMgr.vue'))
                },
                {
                    path: 'text',
                    name: 'Text',
                    component: defineAsyncComponent(() => import('@/views/admin/ArticleMgr.vue'))
                },
                {
                    path: "text/edit/:itemID",
                    name: "EditText",
                    component: defineAsyncComponent(() => import('@/views/admin/ArticleEditor.vue'))
                },
                {
                    path: "text/add",
                    name: "AddText",
                    component: defineAsyncComponent(() => import('@/views/admin/ArticleEditor.vue'))
                },
                {
                    path: "reptile",
                    name: "Reptile",
                    component: defineAsyncComponent(() => import('@/views/admin/Python.vue'))
                }
            ],
            // token验证

            beforeEnter: (to, from, next) => {
                let token = localStorage.getItem('token');
                let logindto = {};
                logindto.token = token;
                logindto.urlpath = to.path;
                if (token != null) { //登录过
                    //验证token
                    request.get("/admin/checkToken").then(res => {
                        //成功
                        if (res.statusCode === 50000) {
                            next();
                        } else {
                            next({path: '/login'});
                        }

                    }).catch(() => {
                        next({path: '/login'});
                    });
                } else { //没有登录
                    next({path: '/login'});
                }

            }
        },
        {
            path: "/:catAll(.*)",
            name: "404",
            component: defineAsyncComponent(() => import('@/views/404.vue'))
        }
    ]
});


export default router;
